<template>
  <div>
    <video
      ref="videoPlayer"
      :poster="poster"
      class="video-js"
    />
  </div>
</template>

<script setup>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

const props = defineProps({
  options: {
    type: Object,
    default: () => ({}),
  },
  poster: {
    type: String,
    default: '',
  },
})

const videoPlayer = ref(null)

onMounted(() => {
  const player = videojs(videoPlayer.value, props.options)

  onBeforeUnmount(() => {
    player.dispose()
  })
})
</script>
