<template>
  <div
    ref="el"
    class="swiper"
  >
    <div class="swiper-wrapper">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import Swiper from 'swiper'
import type { SwiperOptions } from 'swiper/types'
import 'swiper/swiper-bundle.css'

const el = ref()
const swiper: Ref<Swiper | null> = ref(null)

const {
  autoHeight = false,
  loop = false,
} = defineProps<SwiperOptions>()

onMounted(() => {
  swiper.value = new Swiper(el.value, {
    autoHeight,
    loop,
    slidesPerView: 'auto',
    spaceBetween: 20,
  })
})

defineExpose({
  swiper,
})
</script>
