const GOOGLE_API_KEY = 'AIzaSyADoB7WNTxff-93xgc9vq21uXzmRHlh1Fw'
const SHEET_ID = '1es61TonKWnoezu0LSrgxzGhqdoYUPRfcKCpGbnt7fpo'
const SHEET_RANGE = 'Аркуш1!A1:D400'

const loadGoogleSheet = async () => {
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_RANGE}?key=${GOOGLE_API_KEY}`
  const res = await $fetch(url)
  const [heading, ...rows] = res.values ?? []
  const items: Record<string, string>[] = rows.map((row: string[]) => {
    const item: Record<string, string> = {}
    heading.map((key: string, i: number) => item[key] = row[i])
    return item
  })
  return items
}

export const useDonorsStore = defineStore('donors', () => {
  const donors: Ref<Donor[]> = ref([])
  const donorsCount = computed(() => donors?.value?.length || 0)

  const getAllDonors = async () => {
    const rows = await loadGoogleSheet()

    donors.value = rows.map((row: Record<string, string>) => ({
      name: row['Ім\'я'],
      avatar: row['Фото'] ?? row['Аватар'],
      instagram: row['Соц. мережі'],
    }))
  }

  getAllDonors()

  return { donors, donorsCount, getAllDonors }
})
